import { Box, Breakpoint, Container, CssBaseline } from '@mui/material';
import { ReactNode } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// import { useSession } from "../auth/SessionContext";
import ErrorBoundary from './ErrorBoundary';
// import { LocalizationProvider } from "@mui/x-date-pickers-pro";
// import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
// import localeEn from "date-fns/locale/en-CA";
// import localeFR from "date-fns/locale/fr-CA";
// import localeUS from "date-fns/locale/en-US";
import { useAuth } from '../Authentication';
import React from 'react';
import AppTheme from '../AppTheme';


export type ProtectedRoutesProps = {
  header?: ReactNode,
  footer?: ReactNode,
  expireRedirect: string,
  maxWidth?: false | Breakpoint | undefined,
  defaultTheme: boolean
}


const ProtectedRoutes = (props: ProtectedRoutesProps) => {
  //   const { isAuthenticated } = useSession();
  const { isAuthenticated } = useAuth();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  window.addEventListener('MenuOpen', () => {
    console.log('Menu did open')
    setDrawerOpen(true);
  });
  window.addEventListener('MenuClose', () => {
    console.log('Menu did close')
    setDrawerOpen(false);
  });

  const drawerWidth = 210;


  // console.log(`Protected Router : isAuthenticated: ${isAuthenticated()}`);
  if (isAuthenticated()) {
    return (
      <AppTheme disableCustomTheme={props.defaultTheme}>
        {/* <Main open={!drawerOpen} > */}
          <Box sx={{ width: '100%',marginLeft: drawerOpen?`${drawerWidth}px`:'0px'}}>
            {props.header}
            <Container maxWidth={false} >
              {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeUS}> */}
              <CssBaseline />
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
              {/* </LocalizationProvider> */}
            </Container>
            {props.footer}
          </Box>
        {/* </Main> */}
      </AppTheme>
    );
  } else {
    navigate(props.expireRedirect, { state: { "ReturnUrl": location.pathname + location.search } });
    return null;
    // return <Navigate to={props.expireRedirect} state={{"ReturnUrl":location.pathname+location.search}}/>;
  }
};

export default ProtectedRoutes;
