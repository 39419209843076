import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Authentication";
import { makeAuthErrorResponse } from "./AuthErrorResponse";
import { SignInData, SigninId } from "./SigninId";
import dayjs from 'dayjs';

export const serverBasePath = "https://restportalproxy.braincloudservers.com/";
export const xAppId = process.env.REACT_APP_APP_ID ?? '14950';

export type ResponseType = { data: any; status: number, error?: { status_message: string, reason_code: number, status: number } };

export const useServerAPI = () => {
  const navigate = useNavigate();
  const { authTokens, setAuthTokens, getNextPacket, logout } = useAuth();

  const requestQueue: QueuedRequest[] = [];
  let isRequestInProgress = false;

  const getAuthenticatedHeaders = (token?: string) => {
    const sessionToken = sessionStorage.getItem("token");
    if (sessionToken) token = sessionToken;
    return {
      headers: {
        "X-PACKETID": getNextPacket(),
        Authorization: `Bearer ${token ?? authTokens}`,
      },
    };
  };

  const rpp = axios.create({
    baseURL: serverBasePath,
    headers: {
      "X-APPID": xAppId,
      "Content-Type": "application/json",
    },
  });

  const handleAxiosError = (error: any) => {
    console.error("Axios ERROR", error);
  };

  const handleUnexpectedError = (error: any) => {
    console.error("ERROR", error);
  };

  const restoreSession = async (): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      if (authTokens) {
        const restoreToken = { handoffId: authTokens };
        rpp
          .post("authentication/AUTHENTICATE", restoreToken)
          .then((response) => {
            if (response.status === 200) {
              sessionStorage.setItem("token", response.data.token);
              setAuthTokens(response.data.token);
              resolve(response.data.token);
            } else {
              sessionStorage.removeItem("token");
              setAuthTokens(undefined);
              resolve(null);
            }
          })
          .catch((e) => {
            console.error(e);
            sessionStorage.removeItem("token");
            setAuthTokens(undefined);
            resolve(null);
          });
      }
    });
  };

  type ServerReq = {
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
    path: string;
    data?: any;
    try?: number;
  };
  const MAX_REQ_TRIES = 3;

  type QueuedRequest = {
    request: ServerReq;
    token?: string;
    resolve: (value: ResponseType | PromiseLike<ResponseType>) => void;
    reject: (reason?: any) => void;
  };

  const processResponse = async (
    response: ResponseType,
    resolve: (value: any) => void,
    reject: (reason?: any) => void,
    request: ServerReq
  ) => {
    if (response.data?.error) {
      if (response.data?.error.reason_code === 40303) {
        console.debug("ServerAPI: SESSION EXPIRED will see if we have rememberMe set");
        // Session Expired
        if (localStorage.getItem("rememberMe") === "on") {
          console.debug("ServerAPI: SESSION EXPIRED we have rememberMe set, trying to restore now.");
          const newToken = await restoreSession();
          if (newToken) {
            resolve(performRequest(request, newToken));
            return;
          }
        }
      }
      if (response.data?.error.reason_code === 40566) {
        // Out of order pkt, try
        console.warn(`ServerAPI: Out of order packet detected, on try ${request.try} for ${request.path}`);
        if (request.try && request.try < MAX_REQ_TRIES) {
          console.info(`ServerAPI: Re-trying packet for ${request.path}`);
          resolve(performRequest(request));
          return;
        }
      }
      console.error("Got an error willprocess it....", response.data?.error);
      if (response.data?.error.status === 403) {
        setAuthTokens(undefined);
        sessionStorage.removeItem("token");
        navigate("/login", {
          state: { error: makeAuthErrorResponse(response.data.error) },
        });
      } else if (response.data?.error.status === 401) {
        setAuthTokens(undefined);
        sessionStorage.removeItem("token");
        navigate("/login", {
          state: { error: makeAuthErrorResponse(response.data.error) },
        });
      } else if (response.data?.error.status === 423) {
        setAuthTokens(undefined);
        sessionStorage.removeItem("token");
        navigate("/login", {
          state: { error: makeAuthErrorResponse(response.data.error) },
        });
      } else {
        reject(makeAuthErrorResponse(response.data.error));
      }
    } else {
      // console.debug(`ServerAPI: processResponse:  did receive an answer ${response.status}`, response.data);
      resolve(response);
    }
  };

  const performRequest = async (request: ServerReq, token?: string): Promise<ResponseType> => {
    var { method, path, data } = request;
    if (request.try) {
      request.try += 1;
      console.warn(`ServerAPI: Performing retry for packet number ${request.try} for ${request.path} .....`);
    } else request.try = 1;

    console.debug(`ServerAPI: performRequest: ${method} on ${path} with data ....`, data);
    return new Promise(async (resolve, reject) => {
      var cacheBusterPath = path + (path.includes("?") ? "&cb=" : "?cb=") + `${Math.random() * 10000}`;
      try {
        isRequestInProgress = true;
        var response: any;
        switch (method) {
          case "GET":
            response = await rpp.get(path, getAuthenticatedHeaders(token));
            break;
          case "POST":
            response = await rpp.post(cacheBusterPath, data, getAuthenticatedHeaders(token));
            break;
          case "PUT":
            response = await rpp.put(path, data, getAuthenticatedHeaders(token));
            break;
          case "PATCH":
            response = await rpp.patch(path, data, getAuthenticatedHeaders(token));
            break;
          case "DELETE":
            response = await rpp.delete(path, getAuthenticatedHeaders(token));
            break;
        }
        processResponse(response, resolve, reject, request);
        // Process the next request in the queue, if any
        if (requestQueue.length > 0) {
          const nextRequest = requestQueue.shift();
          if (nextRequest) console.debug(`Processing next queued network request ${nextRequest.request.path}`);
          if (nextRequest)
            performRequest(nextRequest.request, nextRequest.token).then(nextRequest.resolve).catch(nextRequest.reject);
        } else {
          isRequestInProgress = false;
        }
      } catch (error) {
        console.error("ServerAPI: -------------error", error);
        if (axios.isAxiosError(error)) {
          if (error.status === 403) {
            logout();
          }
          handleAxiosError(error);
          if (error.response?.status === 423) resolve({ status: 423, data: {} });
        } else {
          handleUnexpectedError(error);
        }
      }
    });
  };

  const submitRequest = async (request: ServerReq, token?: string): Promise<ResponseType> => {
    return new Promise((resolve, reject) => {
      const qRequest: QueuedRequest = {
        request,
        token,
        resolve,
        reject,
      };
      if (!isRequestInProgress) {
        // If no request is currently being processed, execute the request immediately
        performRequest(request, token).then(resolve).catch(reject);
      } else {
        // Add the request to the queue
        console.debug(`Queueing Network request for later ${request.path}`);
        requestQueue.push(qRequest);
      }
    });
  };

  const countCustomEntity = (entityType: string) => async (searchCriteria?: { [prop: string]: any }): Promise<ResponseType> => {

    const context = { pagination: { rowsPerPage: 1, pageNumber: 1 }, searchCriteria: {}, sortCriteria: {} };
    if (searchCriteria) context.searchCriteria = searchCriteria;
    var requestPath = `v2/customEntity/GET_COUNT/${entityType}?whereJson=${encodeURIComponent(JSON.stringify(context.searchCriteria))}`;
    const result = await submitRequest({
      path: requestPath,
      method: "GET",
    });
    if (result.status === 200) {
      console.debug(`Server API: GET_COUNT ${entityType} return`, result);
      return result.data.data.entityListCount;
    }
    return { error: { status_message: "Could not get count", reason_code: 0, status: 500 }, status: 500, data: null };

  };

  const listCustomEntity = (entityType: string) => async (rowsPerPage: number, pageNumber: number, searchCriteria?: { [prop: string]: any }, sortCriteria?: { [prop: string]: any }): Promise<ResponseType> => {

    const context = { pagination: { rowsPerPage, pageNumber }, searchCriteria: {}, sortCriteria: {} };
    if (searchCriteria) context.searchCriteria = searchCriteria;
    if (sortCriteria) context.sortCriteria = sortCriteria;
    var requestPath = `v2/customEntity/GET_ENTITY_PAGE/${entityType}?context=${encodeURIComponent(JSON.stringify(context))}`;
    const result = await submitRequest({
      path: requestPath,
      method: "GET",
    });
    if (result.status === 200) {
      console.debug(`Server API: GET_ENTITY_PAGE ${entityType} return`, result);
      return result.data.data.results.items;
    }
    return { error: { status_message: `Could not ${entityType}`, reason_code: 0, status: 500 }, status: 500, data: null };

  };

  const saveCustomEntity = (entityType: string) => async (entity: any) => {
    var requestPath = `v2/customEntity/UPDATE_ENTITY/${entityType}/${entity.entityId}?version=${entity.version}`;
    const result = await submitRequest({
      path: requestPath,
      method: "PUT",
      data: entity.data
    });
    if (result.status === 200) {
      console.debug(`Server API: UPDATE_ENTITY ${entityType} return`, result);
      return {...result.data.data,data:entity.data};
    }
    return { error: { status_message: `Could not ${entityType}`, reason_code: 0, status: 500 }, status: 500, data: null };
  }

  const createCustomEntity = (entityType: string) => async (data: any) => {
    var acl={other:2};
    var requestPath = `v2/customEntity/CREATE_ENTITY/${entityType}/?acl=${encodeURIComponent(JSON.stringify(acl))}&isOwned=false`;
    const result = await submitRequest({
      path: requestPath,
      method: "POST",
      data: data
    });
    if (result.status === 200) {
      console.debug(`Server API: UPDATE_ENTITY ${entityType} return`, result);
      return {...result.data.data,data};
    }
    return { error: { status_message: `Could not ${entityType}`, reason_code: 0, status: 500 }, status: 500, data: null };
  }


  const listSysGlobalEntity = (entityType: string) => async (maxReturn: number, searchCriteria?: { [prop: string]: any }, sortCriteria?: { [prop: string]: any }): Promise<ResponseType> => {

    (searchCriteria ?? {}).entityType = entityType;

    var requestPath = `v2/globalEntity/GET_LIST?where=${encodeURIComponent(JSON.stringify(searchCriteria))}&orderBy=${encodeURIComponent(JSON.stringify(sortCriteria ?? {}))}&maxReturn=${maxReturn}`;
    const result = await submitRequest({
      path: requestPath,
      method: "GET",
    });
    if (result.status === 200) {
      console.debug(`Server API: GET_ENTITY_PAGE ${entityType} return`, result);
      return result.data.data.entityList;
    }
    return { error: { status_message: `Could not ${entityType}`, reason_code: 0, status: 500 }, status: 500, data: null };

  };

  const runScript = (script:string) => async (params:any): Promise<StatsData|ResponseType> => {
    return submitRequest({
      path: `v2/script/RUN/${script}`,
      method: "POST",
      data: params,
    });
  };

  return {
    // ************************************************
    // Unauthneticated calls
    // ************************************************

    signIn: (credentials: SignInData) => {
      // reset packet id at login.
      // sessionStorage.setItem("X-PACKETID", "0");
      return rpp.post(`v2/authentication/AUTHENTICATE`, credentials, { headers: { "x-error-output": "ext" } });
    },

    resetEmailPassword: (data: SigninId) => performRequest({ path: "authentication/FORGOT", method: "POST", data }),

    // signUp: async (data: SignUpData) => {
    //   const signupResult = await performRequest({
    //     path: "authentication/SIGNUP",
    //     method: "POST",
    //     data,
    //   });
    //   console.debug(`Signup: results`, signupResult);
    //   if (signupResult.status === 200) {
    //     setAuthTokens(signupResult.data.token);
    //     // Call script to register extra data.
    //     const { password, ...userDetails } = data;
    //     return await performRequest({
    //       path: "script/RUN/UpdateUserDetails",
    //       method: "POST",
    //       data: userDetails,
    //     });
    //   }
    //   return;
    // },

    validateTotp: (totpRequest: { requestId: string, totpCode: string }) => {
      console.log(`Calling validateTotp with `, totpRequest);
      return rpp.post(`v2/authentication/AUTHENTICATE`,
        { username: totpRequest.requestId, password: totpRequest.totpCode },
        { headers: { "x-error-output": "ext" } });
    },


    // ************************************************
    // Authneticated calls
    // ************************************************

    countCustomers: countCustomEntity("Customer"),
    listCustomers: listCustomEntity("Customer"),
    saveCustomer: runScript("SaveCustomer"),
    countFleet: countCustomEntity("CustomerFleet"),
    listFleet: listCustomEntity("CustomerFleet"),
    countFlight: countCustomEntity("Flight"),
    listFlight: listCustomEntity("Flight"),
    saveFleet: saveCustomEntity("CustomerFleet"),
    countInvoice: countCustomEntity("Invoices"),
    listInvoice: listCustomEntity("Invoices"),
    countAerodrome: countCustomEntity("Aerodrome"),
    listAerodrome: listCustomEntity("Aerodrome"),
    countAircraftType: countCustomEntity("AircraftType"),
    listAircraftType: listCustomEntity("AircraftType"),
    countRate: countCustomEntity("Rates"),
    listRate: listCustomEntity("Rates"),
    countTaxe: countCustomEntity("TaxSatus"),
    listTaxe: listCustomEntity("TaxSatus"),
    listRuleDefinitions: listCustomEntity("RuleDefinition"),
    saveRuleDefinitions: saveCustomEntity("RuleDefinition"),
    createRuleDefinitions: createCustomEntity("RuleDefinition"),

    listBatchSummaries: listSysGlobalEntity("BatchSummary"),
    processBatch: runScript("ProcessBatch"),
    sendFlightEmail: runScript("SendEmail"),
    listInvoicableCusomer: runScript("GetInvoicableCustomers"),
    listScheduledBatches: runScript("listScheduledBatches"),

    readCustomer: async (entityId: string): Promise<ResponseType> => {
      var requestPath = `v3/customEntity/Customer/${entityId}`;
      const result = await submitRequest({
        path: requestPath,
        method: "GET",
      });
      if (result.status === 200) {
        console.debug(`Server API: readCustomer return`, result);
        return result.data.data;
      }
      return { error: { status_message: "Could not read Customer", reason_code: 0, status: 500 }, status: 500, data: null };
    },

    // countCustomersV3: async (searchCriteria?: { [prop: string]: any }): Promise<ResponseType> => {

    //   const context = { pagination: { rowsPerPage: 1, pageNumber: 1 }, searchCriteria: {}, sortCriteria: {} };
    //   if (searchCriteria) context.searchCriteria = searchCriteria;
    //   var requestPath = "v3/customEntity/Customer?where=" + encodeURIComponent(JSON.stringify(context.searchCriteria)) + "&count=true";
    //   // var requestPath =  "v3/customEntity/Customer?context=" + encodeURIComponent(JSON.stringify(context)) + "&count=true";
    //   const result = await submitRequest({
    //     path: requestPath,
    //     method: "GET",
    //   });
    //   if (result.status === 200) {
    //     console.debug(`Server API: countCustomers return`, result);
    //     return result.data.data.entityListCount;
    //   }
    //   return { error: { status_message: "Could not get count", reason_code: 0, status: 500 }, status: 500, data: null };

    // },

    // listCustomersV3: async (rowsPerPage: number, pageNumber: number, searchCriteria?: { [prop: string]: any }, sortCriteria?: { [prop: string]: any }): Promise<ResponseType> => {

    //   const context = { pagination: { rowsPerPage, pageNumber }, searchCriteria: {}, sortCriteria: {} };
    //   if (searchCriteria) context.searchCriteria = searchCriteria;
    //   if (sortCriteria) context.sortCriteria = sortCriteria;
    //   var requestPath = "v3/customEntity/Customer?context=" + encodeURIComponent(JSON.stringify(context));
    //   const result = await submitRequest({
    //     path: requestPath,
    //     method: "GET",
    //   });
    //   if (result.status === 200) {
    //     console.debug(`Server API: listCustomers return`, result);
    //     return result.data.data.results.items;
    //   }
    //   return { error: { status_message: "Could not listCustomers", reason_code: 0, status: 500 }, status: 500, data: null };

    // },


    invoiceStats: async (params:any): Promise<StatsData|ResponseType> => {
      const today = new Date();
      const lastMonth = dayjs(today).add(-1,'month');
      const requestData = { from: lastMonth.toISOString(), to: today.toISOString(), ...params };
      const result = await submitRequest({
        path: "v2/script/RUN/StatsInvoices",
        method: "POST",
        data: requestData,
      });
      if (result.status === 200) {
        console.debug(`Server API: SomeScript return`, result);
        return result.data.data.response as StatsData;
      }
      return { error: { status_message: "Could not run SomeScript", reason_code: 0, status: 500 } } as ResponseType;
    },

    performRequest: submitRequest,

    /**
     *
     * @returns { data: {
     *                success: true,
     *                response: {
     *                  profile: ...
     *                }
     *              }
     *          }
     */
  };
};
