import React, { useEffect, useState } from 'react'
import { useServerAPI } from '../Session';
import { Paper, Typography } from '@mui/material';
import { DataGridPro as DataGrid, GridActionsCellItem, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Authentication';
import { filterToSearchCriteria, sortToSortCriteria } from '../Session/Helpers';
import { CommonToolbar } from '../components/CommonToolbar';

function CustomerListPage() {

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState<any[]>();
    const [customerCount, setCustomerCount] = useState(-1);
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0,
    });
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: []
    })
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{field:'data.Customer_Number',sort:'asc'}]);
    const { userData } = useAuth();

    const server = useServerAPI();
    const navigate = useNavigate();


    const columns: GridColDef[] = [
        {
            field: 'data.Customer_Number',
            valueGetter: (v, r) => {
                return r?.data.Customer_Number
            },
            headerName: 'ID',
            width: 90
        },
        {
            field: 'data.Customer',
            valueGetter: (v, r: any) => r?.data.Customer,
            headerName: 'Customer',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.ICAOcode',
            valueGetter: (v, r: any) => r?.data.ICAOcode,
            headerName: 'ICAO Code',
            width: 150,
            //   editable: true,
        },
        {
            field: 'data.Contact',
            valueGetter: (v, r: any) => r?.data.Contact,
            headerName: 'Contact',
            flex: 1
            //   editable: true,
        },
        {
            field: 'data.city',
            valueGetter: (v, r: any) => r?.data.city,
            headerName: 'City',
            width: 160,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    onClick={handleEditCustomer(params.row.entityId)}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="Other Action"
                    // onClick={toggleAdmin(params.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Other Other Action"
                    // onClick={duplicateUser(params.id)}
                    showInMenu
                />,
            ],
        },
    ];

    const handleFilterModelChange = (filterModel: GridFilterModel) => {
        console.debug(`filterModel`, filterModel);
        setCustomerCount(-1);
        setFilterModel(filterModel);
    }
    const handleSortModelChange = (newsortModel: GridSortModel) => {
        console.debug(`filterModel`, newsortModel);
        setSortModel(newsortModel);
    }
    const handleEditCustomer = (entityId: string) => () => {
        navigate(`/adm/customer/${entityId}`);
    }

    useEffect(() => {
        if (customerCount === -1) {
            server.countCustomers(filterToSearchCriteria(filterModel, userData?.authPayload?.Customer_Number)).then(r => {
                if (typeof r === 'number') {
                    setCustomerCount(r);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterModel]);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            server.listCustomers(paginationModel.pageSize, paginationModel.page + 1, filterToSearchCriteria(filterModel, userData?.authPayload?.Customer_Number), sortToSortCriteria(sortModel)).then(r => {
                if (r) {
                    if (Array.isArray(r)) {
                        setCustomers(r);
                        console.log(`Got customer list ${r.length}`);
                    }
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel, filterModel, sortModel])



    React.useEffect(() => {
        if (customers && customers.length === 1 && userData?.authPayload?.Customer_Number) {
            console.log(`Got 1 customer, will go directly to ${customers[0].entityId}`);
            navigate(`/adm/customer/${customers[0].entityId}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, customers])

    return (
        <Paper elevation={1} sx={{ margin: '20px' }}>
            <Typography variant='h5'>Customer List</Typography>
            <DataGrid
                slots={{ toolbar: CommonToolbar }}
                loading={loading}
                pagination
                rows={customers ?? []}
                getRowId={(r) => r.entityId}
                columns={columns}
                rowCount={customerCount}
                paginationMode='server'
                onFilterModelChange={handleFilterModelChange}
                filterModel={filterModel}
                filterMode='server'
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                // autoPageSize
                autoHeight
            // pageSizeOptions={[5,10,20]}
            />
        </Paper>
    )
}

export default CustomerListPage 