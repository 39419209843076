import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material';
import { useAuth } from '../Authentication';

import logo from '../assets/logo-nav-canada-blue.svg';
import PersonIcon from '@mui/icons-material/Person';
import FlightIcon from '@mui/icons-material/Flight';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RuleIcon from '@mui/icons-material/Rule';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ContrastIcon from '@mui/icons-material/Contrast';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import QuizIcon from '@mui/icons-material/Quiz';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ApartmentIcon from '@mui/icons-material/Apartment';

interface MenuAppBarProps {
  onToggleTheme: () => void;
}

export default function MenuAppBar({ onToggleTheme }: MenuAppBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { userData } = useAuth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    navigate('/logout');
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  interface MenuItem {
    name: string,
    icon: React.ReactNode,
    route: string
  }

  const mainmenu: MenuItem[] = [
    { name: "Dashboard", icon: <DashboardIcon />, route: "/adm/dashboard" },
    { name: userData?.authPayload?.Customer_Number ? "Profile" : "Customers", icon: userData?.authPayload?.Customer_Number ? <BusinessIcon /> : <PersonIcon />  , route: "/adm/customers" },
    // { name: "Fleets", icon: <ConnectingAirportsIcon />, route: "/adm/fleets" },
  ];

  const adminmenu: MenuItem[] = [
    { name: "Airplane Types", icon: <FlightIcon />, route: "/adm/aircrafts" },
    { name: "Airports", icon: <ApartmentIcon />, route: "/adm/aerodromes" },
    { name: "Rules", icon: <RuleIcon />, route: "/adm/rules" },
    { name: "Rates", icon: <RequestQuoteIcon />, route: "/adm/rates" },
    { name: "Taxes", icon: <MonetizationOnIcon />, route: "/adm/taxes" },
    { name: "Unknowns", icon: <QuizIcon />, route: "/adm/unknowns" },
    { name: "Processing", icon: <BatchPredictionIcon />, route: "/adm/processing" },
  ];

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
    emitEvent(newOpen);
  };

  const goToPage = (route: string) => () => {
    navigate(route);
  }

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  }));

  const emitEvent = (open:boolean) => {
    const event = new Event(open ? 'MenuOpen' : 'MenuClose');
    window.dispatchEvent(event); // Dispatch a custom event
  };

  console.log('Current user', userData);
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {mainmenu.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton onClick={goToPage(item.route)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {userData?.role === 'admin' && adminmenu.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton onClick={goToPage(item.route)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  //sx={{position:'absolute',top:1,left:1}}
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt="Icon"
            style={{
              width: '100px',
              height: '50px',
              filter: 'invert(100%) brightness(1000%)'
            }}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            IBS
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile {userData?.name}</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        >
        <DrawerHeader>
        <IconButton size="small" onClick={() => onToggleTheme()} sx={{ marginRight: 1 }}><ContrastIcon /></IconButton>
          <IconButton onClick={toggleDrawer(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        {DrawerList}
      </Drawer>

    </Box>
  );
}