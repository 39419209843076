import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useNavigate } from 'react-router-dom';

export const DisputedCard = () => {
    const [count, setCount] = React.useState<number>();
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
            setCount(2);
    },[]);
    return (

        <Card variant='elevation'  sx={{aspectRatio:1, minHeight:"170px", margin:1, border:'2px solid red', cursor:'pointer'}} >
            <div onClick={()=>{navigate('/adm/unknowns',{state:{tab:3}})}} >
            <CardHeader title="Disputed" avatar={<ThumbDownIcon/>}/>
            <CardContent >
                Unassigned
                <Typography variant='h2'>{count}</Typography>
            </CardContent>
</div>
        </Card>
    )
}
