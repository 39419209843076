import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import { useServerAPI } from '../Session';
import PersonIcon from '@mui/icons-material/Person';

export const CustomerCard = () => {
    const [count, setCount] = React.useState<number>();

    const server = useServerAPI();

    React.useEffect(() => {
        if (count === undefined) {
            server.countCustomers().then(value => {
                if (typeof value === 'number') {
                    setCount(value);
                }
            });
        }
    }, [])
    return (
        <Card variant='elevation'  sx={{aspectRatio:1, minHeight:"170px", margin:1}}>
            <CardHeader title="Customers" avatar={<PersonIcon/>}/>
            <CardContent>
                <Typography variant='h2'>{count}</Typography>
            </CardContent>

        </Card>
    )
}
