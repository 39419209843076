import { Card, CardContent, CardHeader } from '@mui/material'
import React from 'react'
import { useServerAPI } from '../Session';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { isStatsData } from '../types/Stats.helper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useAuth } from '../Authentication';

export const InvoiceCard = () => {
    const [stats, setStats] = React.useState<StatsData>();
    const {userData} = useAuth();

    const server = useServerAPI();

    React.useEffect(() => {
        if (stats === undefined) {
            var params = userData?.authPayload?.Customer_Number ? {"Customer_Number":userData?.authPayload?.Customer_Number} : {};
            server.invoiceStats(params).then(value => {
                console.log(`Stats is `,value);
                if (isStatsData(value)) {
                    setStats(value);
                }
            });
        }
    })
    return (
        <Card variant='elevation' sx={{ aspectRatio: 1, minHeight: "170px", margin: 1 }}>
            <CardHeader title="Invoices Paid" avatar={<ReceiptIcon />} />
            <CardContent sx={{height:"130px",padding:0}}>
                <Gauge
                    value={stats?.current ?? 0}
                    startAngle={-90}
                    endAngle={90}
                    valueMax={stats?.total}
                    valueMin={0}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            // fontSize: 40,
                            transform: 'translate(0px, -15px)',
                        },
                    }}
                    text={
                       ({ value, valueMax }) => `${value} / ${valueMax}`
                    }
                />
            </CardContent>

        </Card>
    )
}
