import { Box, Container, Paper, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { DataGridPro as DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import React from 'react'
import '../App.css';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useLocation } from 'react-router-dom';

export const Unknown = () => {
    const custWUnknowns: Entity<any>[] = [
        {
            entityId: "",
            acl: { other: 2 },
            createdAt: 0,
            updatedAt: 0,
            version: 1,
            data: {
                "BC_REC_STATUS": null,
                "Customer": "Sample Customer ",
                "Customer_Number": "3499",
                "ICAOcode": "<UNKNOWN>",
                "add1": "Attn: Accounts Payable #1",
                "add2": "3732 Main Street",
                "city": "Orlando",
                "province": "FL",
                "country": "United States of America",
                "postalcode": "33413",
                "Contact": "John Doe #2499",
                "phone_no": "+1 (404) 5551234 xM",
                "cphone2": "+1 (404) 5551235 xH",
                "cemail": "3499 Invoice Generation Customer@companyname.com",
                "cycle": "2",
                "custtype": "P",
                "lst_billed": "04Apr2024 0:00:00.000",
                "Customer_TAX_status": "3",
                "edi_client": "2",
                "lstupd_flt": "20May2022 0:00:00.000",
                "export": null,
                "fleetrptdt": "04Dec2023 15:50:21.000",
                "cexpcode": "I",
                "PDF_IND": "2"
            }
        } as Entity<any>];

    const disputedInvoice: Entity<any>[] = [
        {
            entityId: "",
            acl: { other: 2 },
            createdAt: 0,
            updatedAt: 0,
            version: 1,
            data: {
                "Customer_Number": "3336",
                "companyName": "3336 Invoice Generation Customer",
                "companyPhone": "+1 (902) 5551234",
                "companyEmail": "3336 Invoice Generation Customer@companyname.com",
                "amount": 6150602.841898052,
                "lines": [
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-06",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 2508.1111618108157,
                        "adj": 2508.1111618108157,
                        "total": 5016.222323621631
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 1974.9335178683862,
                        "adj": 1974.9335178683862,
                        "total": 3949.8670357367723
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 2138.1511639732116,
                        "adj": 2138.1511639732116,
                        "total": 4276.302327946423
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 2138.1511639732116,
                        "adj": 2138.1511639732116,
                        "total": 4276.302327946423
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 1974.9335178683862,
                        "adj": 1974.9335178683862,
                        "total": 3949.8670357367723
                    },
                    {
                        "date": "2024-03-10",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 1974.9335178683862,
                        "adj": 1974.9335178683862,
                        "total": 3949.8670357367723
                    },

                ],
                "receiptNumber": 1,
                "datePaid": "2024-09-30",
                "paymentMethod": "",
                "batchName": "Sce341",
                "status": "Disputed"
            }
        } as Entity<any>,
        {
            entityId: "B",
            acl: { other: 2 },
            createdAt: 0,
            updatedAt: 0,
            version: 1,
            data: {
                "Customer_Number": "2310",
                "companyName": "2310 Invoice Generation Customer",
                "companyPhone": "+1 (902) 5551234",
                "companyEmail": "2310 Invoice Generation Customer@companyname.com",
                "amount": 61602.841898052,
                "lines": [
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-05",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 119.6929404768719,
                        "adj": 119.6929404768719,
                        "total": 239.3858809537438
                    },
                    {
                        "date": "2024-03-06",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 2508.1111618108157,
                        "adj": 2508.1111618108157,
                        "total": 5016.222323621631
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 1974.9335178683862,
                        "adj": 1974.9335178683862,
                        "total": 3949.8670357367723
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 2138.1511639732116,
                        "adj": 2138.1511639732116,
                        "total": 4276.302327946423
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 2138.1511639732116,
                        "adj": 2138.1511639732116,
                        "total": 4276.302327946423
                    },
                    {
                        "date": "2024-03-09",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 1974.9335178683862,
                        "adj": 1974.9335178683862,
                        "total": 3949.8670357367723
                    },
                    {
                        "date": "2024-03-10",
                        "aircraft": "DH8D",
                        "desc": null,
                        "category": "MTOW < 100",
                        "base": 1974.9335178683862,
                        "adj": 1974.9335178683862,
                        "total": 3949.8670357367723
                    },

                ],
                "receiptNumber": 1,
                "datePaid": "2024-09-30",
                "paymentMethod": "",
                "batchName": "Sce341",
                "status": "Disputed"
            }
        } as Entity<any>];

    const location = useLocation();

    const [currentTab, setCurrentTab] = React.useState(0);
    const [loading] = React.useState(false);
    const [customers] = React.useState<any[]>(custWUnknowns);
    const [customerCount] = React.useState(1);
    const [invoices] = React.useState<any[]>(disputedInvoice);
    const [invoicesCount] = React.useState(2);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };
    const currencyFormmater = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' })

    const customerColumns: GridColDef[] = [
        {
            field: 'data.Customer_Number',
            valueGetter: (v, r) => {
                return r?.data.Customer_Number
            },
            headerName: 'ID',
            width: 90
        },
        {
            field: 'data.Customer',
            valueGetter: (v, r: any) => r?.data.Customer,
            headerName: 'Customer',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.ICAOcode',
            valueGetter: (v, r: any) => r?.data.ICAOcode,
            headerName: 'ICAO Code',
            width: 150,
            cellClassName: 'error'

            //   editable: true,
        },
        {
            field: 'data.Contact',
            valueGetter: (v, r: any) => r?.data.Contact,
            headerName: 'Contact',
            flex: 1
            //   editable: true,
        },
        {
            field: 'data.city',
            valueGetter: (v, r: any) => r?.data.city,
            headerName: 'City',
            width: 160,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,

            getActions: () => [
                <Tooltip title={"Assign to"}>
                    <GridActionsCellItem
                        icon={<AssignmentIndIcon />}
                        label="Assign to"
                        onClick={()=>alert('Assignment popup window placeholder')}
                    />
                </Tooltip>
                ,
            ],
        },
    ];

    const invoiceColumns: GridColDef[] = [
        {
            field: 'data.datePaid',
            valueGetter: (v, r) => {
                return r?.data.datePaid
            },
            headerName: 'Date',
            width: 120
        },
        {
            field: 'data.Company_Number',
            valueGetter: (v, r: any) => r?.data.Customer_Number,
            headerName: 'Customer #',
            width: 120
            //   editable: true,
        },
        {
            field: 'data.companyName',
            valueGetter: (v, r: any) => r?.data.companyName,
            headerName: 'Desc',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.amount',
            valueGetter: (v, r: any) => r?.data.amount,
            align: 'right',
            valueFormatter: (v) => currencyFormmater.format(v),
            flex: 1.5,
            //   editable: true,
            headerName: 'Amount',
            headerAlign: 'right',
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,

            getActions: () => [
                <Tooltip title={"Assign to"}>
                    <GridActionsCellItem
                        icon={<AssignmentIndIcon />}
                        label="Assign to"
                        onClick={()=>alert('Assignment popup window placeholder')}
                    />
                </Tooltip>
                ,
            ],
        },
    ];

    React.useEffect(()=>{
        if (location.state && typeof location.state.tab === 'number') {
            if (location.state.tab >0 && location.state.tab < 4) {
                setCurrentTab(location.state.tab);
            }
        }
    },[location.state]);

    return (
        <Paper sx={{ marginTop: '5px', padding: 1 }}>
            <Box flexDirection={'column'}>
                <Typography variant='h4' margin={1}>Records with Issues & Discrepancies</Typography>
                <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Customers" value={0} />
                    <Tab label="Flights" value={1} />
                    <Tab label="Fleets" value={2} />
                    <Tab label="Invoices" value={3} />
                </Tabs>
                <Container >
                    <div hidden={currentTab !== 0}>
                        <Box display={'block'} justifyContent={'center'} width={'100%'} visibility={currentTab === 0 ? 'visible' : 'hidden'}>
                            <Typography variant='h5' margin={1}>Customer With Unknown data</Typography>
                            <DataGrid
                                loading={loading}
                                pagination
                                checkboxSelection
                                rows={customers ?? []}
                                getRowId={(r) => r.entityId}
                                columns={customerColumns}
                                rowCount={customerCount}
                                // paginationMode='server'
                                // onFilterModelChange={handleFilterModelChange}
                                // filterModel={filterModel}
                                // filterMode='server'
                                // onPaginationModelChange={setPaginationModel}
                                // paginationModel={paginationModel}
                                // sortingMode='server'
                                // sortModel={sortModel}
                                // onSortModelChange={handleSortModelChange}
                                // autoPageSize
                                autoHeight
                            // pageSizeOptions={[5,10,20]}
                            />
                        </Box>
                    </div>
                    <div hidden={currentTab !== 1}>
                        <Box display={'flex'} justifyContent={'center'} width={'100%'} visibility={currentTab === 1 ? 'visible' : 'hidden'}>
                            <Typography variant='caption'>Not yet implemented</Typography>
                        </Box>
                    </div>
                    <div hidden={currentTab !== 2}>
                        <Box display={'flex'} justifyContent={'center'} width={'100%'} visibility={currentTab === 2 ? 'visible' : 'hidden'}>
                            <Typography variant='caption'>Not yet implemented</Typography>
                        </Box>
                    </div>
                    <div hidden={currentTab !== 3}>
                        <Box display={'block'} justifyContent={'center'} width={'100%'} visibility={currentTab === 3 ? 'visible' : 'hidden'}>
                            <Typography variant='h5' margin={1}>Disputed Invoices</Typography>
                            <DataGrid
                                loading={loading}
                                pagination
                                checkboxSelection
                                rows={invoices ?? []}
                                getRowId={(r) => r.entityId}
                                columns={invoiceColumns}
                                rowCount={invoicesCount}
                                // paginationMode='server'
                                // onFilterModelChange={handleFilterModelChange}
                                // filterModel={filterModel}
                                // filterMode='server'
                                // onPaginationModelChange={setPaginationModel}
                                // paginationModel={paginationModel}
                                // sortingMode='server'
                                // sortModel={sortModel}
                                // onSortModelChange={handleSortModelChange}
                                // autoPageSize
                                autoHeight
                            // pageSizeOptions={[5,10,20]}
                            />
                        </Box>

                    </div>
                </Container>
            </Box>
        </Paper >
    )
}
