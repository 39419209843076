import { Box, Button, FormControl, FormGroup, FormLabel, TextField } from '@mui/material'
// import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React from 'react'
// import { useServerAPI } from '../Session'

interface CustomerInfoProps {
  customer: any
  onSave: (customer: any) => void
}

export const CustomerInfo = ({ customer, onSave }: CustomerInfoProps) => {


  const [currentCustomer, setCurrentCustomer] = React.useState<any>(null);
  const [email, setEmail] = React.useState<string>("");

  React.useEffect(() => {
    if (customer) {
      setCurrentCustomer(customer);
      setEmail(customer.data.cemail);
    }
  }, [customer])

  const fieldVariant = 'outlined';
  const labelWidth = "200px";



  const fieldChangeHandler = (field: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log(`${field} => ${event.target.value}`);
    if (field === 'cemail') setEmail(event.target.value);
  }

  const saveCustomerHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const newCustomer = { ...customer };
    newCustomer.data.cemail = email;
    onSave(newCustomer);
  }

  return (
    <Box maxHeight={"800px"} width={"600px"} padding={"20px"}>
      {currentCustomer && <FormGroup sx={{ width: '700px', }} >
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          {/* <Box paddingRight={'1em'} width={labelWidth}> */}
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="ID">ID</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="Customer_Number"
            type="text"
            id="Customer_Number"
            autoFocus
            required
            fullWidth
            variant={fieldVariant} size='small'

            value={currentCustomer?.data.Customer_Number}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="Name">Name</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="Name"
            type="text"
            id="customerName"
            autoFocus
            required
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.Customer}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="ICAOcode">ICAOcode</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="ICAOcode"
            type="text"
            id="customerName"
            autoFocus
            required
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.ICAOcode}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="Address">Address</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="Name"
            type="text"
            id="add1"
            autoFocus
            required
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.add1}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="Name"
            type="text"
            id="add2"
            autoFocus
            required
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.add2}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="city">City</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="city"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='city'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.city}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="province">province</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="province"
            type="text"
            id="province"
            autoFocus
            required
            placeholder='province'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.province}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="country">country</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="city"
            type="text"
            id="country"
            autoFocus
            required
            placeholder='country'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.country}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="postalcode">postalcode</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="postalcode"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='postalcode'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.postalcode}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="Contact">Contact</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="Contact"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='Contact'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.Contact}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="phone_no">phone_no</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="phone_no"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='phone_no'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.phone_no}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="cphone2">cphone2</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="cphone2"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='cphone2'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.cphone2}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="cphone2">cemail</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="cemail"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='cemail'
            fullWidth
            inputMode='email'
            variant={fieldVariant} size='small'
            value={email}
            onChange={fieldChangeHandler('cemail')}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="cycle">cycle</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="cycle"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='cycle'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.cycle}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="custtype">custtype</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="custtype"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='custtype'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.custtype}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="lst_billed">lst_billed</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="lst_billed"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='lst_billed'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.lst_billed}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="cphoCustomer_TAX_statusne2">TAX status</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="Customer_TAX_status"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='Customer_TAX_status'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.Customer_TAX_status}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="lstupd_flt">lstupd_flt</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="lstupd_flt"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='lstupd_flt'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.lstupd_flt}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
        <FormControl fullWidth sx={{ flexDirection: 'row' }}>
          <Box paddingRight={'1em'} width={labelWidth}>
            <FormLabel htmlFor="PDF_IND">PDF_IND</FormLabel>
          </Box>
          <TextField
            // error={}
            // helperText={passwordErrorMessage}
            name="PDF_IND"
            type="text"
            id="add2"
            autoFocus
            required
            placeholder='PDF_IND'
            fullWidth
            variant={fieldVariant} size='small'
            value={currentCustomer?.data.PDF_IND}
            color={false ? 'error' : 'primary'}
          />
        </FormControl>
      </FormGroup>}
      <Button variant='contained' onClick={saveCustomerHandler} >Save</Button>
    </Box>
  );
}
