import React, { useEffect, useState } from 'react'
import { useServerAPI } from '../Session';
import { Paper, Typography } from '@mui/material';
import { DataGridPro as DataGrid, GridActionsCellItem, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useAuth } from '../Authentication';
import { CommonToolbar } from '../components/CommonToolbar';

function AircraftListPage() {

    const [loading,setLoading] = useState(false);
    const [customers, setCustomers] = useState<any[]>();
    const [customerCount, setCustomerCount] = useState(-1);
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0,
    });
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: []
    })
    const [sortModel, setSortModel] = React.useState<GridSortModel>();
    const { userData } = useAuth();
    
    const server = useServerAPI();


    const columns: GridColDef[] = [
        {
            field: 'data.ac_type',
            valueGetter: (v, r) => {
                return r?.data.ac_type
            },
            headerName: 'ID',
            width: 90
        },
        {
            field: 'data.MTOW',
            valueGetter: (v, r: any) => r?.data.MTOW,
            headerName: 'MTOW',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.Min_MTOW',
            valueGetter: (v, r: any) => r?.data.Min_MTOW,
            headerName: 'Min MTOW',
            width: 150,
            //   editable: true,
        },
        {
            field: 'data.Description',
            valueGetter: (v, r: any) => r?.data["Description "],
            headerName: 'Description',
            flex: 1
            //   editable: true,
        },
        {
            field: 'data.category',
            valueGetter: (v, r: any) => r?.data.category,
            headerName: 'Category',
            width: 160,
        },
        {
            field: 'data.Number of \nEngines',
            valueGetter: (v, r: any) => r?.data["Number of \nEngines"],
            headerName: 'Engines',
            width: 160,
        },
        {
            field: 'data.Powerplant',
            valueGetter: (v, r: any) => r?.data.Powerplant,
            headerName: 'Powerplant',
            width: 160,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    // onClick={handleEditCustomer(params.row.entityId)}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="Other Action"
                    // onClick={toggleAdmin(params.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Other Other Action"
                    // onClick={duplicateUser(params.id)}
                    showInMenu
                />,
            ],
        },
    ];

    const handleFilterModelChange = (filterModel: GridFilterModel) => {
        console.debug(`filterModel`, filterModel);
        setCustomerCount(-1);
        setFilterModel(filterModel);
    }
    const handleSortModelChange = (newsortModel: GridSortModel) => {
        console.debug(`filterModel`, newsortModel);
        setSortModel(newsortModel);
    }

    useEffect(() => {
        if (customerCount === -1) {
            server.countAircraftType(filterToSearchCriteria(filterModel)).then(r => {
                if (typeof r === 'number') {
                    setCustomerCount(r);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterModel]);

    useEffect(() => {
        if (!loading) {
            setLoading(true);
            server.listAircraftType(paginationModel.pageSize, paginationModel.page + 1, filterToSearchCriteria(filterModel), sortToSortCriteria(sortModel)).then(r => {
                if (r) {
                    if (Array.isArray(r)) {
                        setCustomers(r);
                        console.log(`Got customer list ${r.length}`);
                    }
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel, filterModel, sortModel])

    React.useEffect(()=>{
        if (userData?.authPayload?.Customer_Number) {
            
        }
    },[userData])

    const filterToSearchCriteria = (filters: GridFilterModel) => {
        var searchCriteria: { [prop: string]: any } = {};
        for (const filter of filters.items) {
            switch (filter.operator) {
                case "contains":
                    searchCriteria[filter.field] = { "$regex": `${filter.value}` };
                    break;
                case "equals":
                    searchCriteria[filter.field] = filter.value;
                    break;
                case "startsWith":
                    searchCriteria[filter.field] = { "$regex": `^${filter.value}` };
                    break;
                case "endsWith":
                    searchCriteria[filter.field] = { "$regex": `${filter.value}$` };
                    break;
                case "isEmpty":
                    searchCriteria[filter.field] = { "$exists": false };
                    break;
                case "isNotEmpty":
                    searchCriteria[filter.field] = { "$exists": true };
                    break;
                case "isAnyOf":
                    searchCriteria[filter.field] = { "$in": filter.value };
                    break;

                default:
                    break;
            }
        }
        if (userData?.authPayload?.Customer_Number) {
            // if the user has a set customer then force it.
            searchCriteria["data.Customer_Number"] = userData?.authPayload?.Customer_Number;   
        }
        return searchCriteria;
    }

    const sortToSortCriteria = (sortList?: GridSortModel) => {
        var sortCriteria: { [prop: string]: any } = {};
        if (sortList) {
            for (const sort of sortList) {
                sortCriteria[sort.field] = sort.sort === 'asc' ? 1 : -1;
            }
        }
        return sortCriteria;
    }

    return (
        <Paper elevation={1} sx={{ margin: '20px' }}>
            <Typography variant='h5'>Aircraft Types</Typography>            
            <DataGrid
                slots={{ toolbar: CommonToolbar }}
                loading={loading}
                pagination
                rows={customers ?? []}
                getRowId={(r) => r.entityId}
                columns={columns}
                rowCount={customerCount}
                paginationMode='server'
                onFilterModelChange={handleFilterModelChange}
                filterModel={filterModel}
                filterMode='server'
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                // autoPageSize
                autoHeight
                // pageSizeOptions={[5,10,20]}
            />
        </Paper>
    )
}

export default AircraftListPage 