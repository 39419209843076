import { Button, Typography } from "@mui/material";
import React from "react";
// import { eventLogger } from '../api/EventLogger';
interface PROPS {
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

interface STATE {
  hasError: boolean;
  error: string | null;
  name: string | null;
}

class ErrorBoundary extends React.Component<PROPS, STATE> {
  state: Readonly<STATE> = {
    hasError: false,
    error: null,
    name: null
  };

  static getDerivedStateFromError(_error: any) {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // if (!!!this.state.name) eventLogger({ appName: "Client", message: error.message ?? "Component error", data: JSON.stringify(error), stackTrace: error.stack ?? "", url: `${window.location.pathname}` });
    this.setState({error: "" + error.stack, name:error.message});
  }

  handleReloadComponent = () => {
    this.setState({hasError: false, error: null, name:null});
  };

  prepareForCopy(value: any) {
    if (value != null) {
      if (typeof value === "string") {
        try {
          return JSON.stringify(JSON.parse(value), undefined, 2);
        } catch (error) {
          return "" + value;
        }
      } else {
        if (typeof value === "object") {
          return JSON.stringify(value, undefined, 2);
        } else {
          return "" + value;
        }
      }
    } else {
      return "";
    }
  }

  writeText(value: any) {
    console.log(`About to copy to clipboard this`, value);
    navigator.clipboard
      .writeText(this.prepareForCopy(value))
      .then(() => {
        // toast.success(<Trans>Copied to clipboard!</Trans>);
      })
      .catch(() => {
        // toast.error(<Trans>Copy to clipboard Failed!</Trans>);
      });
  }

  handleCopyToClipboard = () => {
    const {hasError, error} = this.state;

    if (hasError) {
      this.writeText(error);
    }
  };

  render() {
    const {hasError} = this.state;

    if (hasError) {
      return (
        <div style={{margin: "auto", padding: "50px", maxWidth: "500px", borderRadius: "15px", border:"2px dashed red", backgroundColor: "yellow", display: "flow", alignContent: "center"}}>
          <div style={{display: "flow", textAlign: "center",marginBottom:'2em'}}>
            <Typography variant='h6'>Sorry, Application encountered an error.</Typography>
            <Typography variant='body1'>{this.state.name}</Typography>
          </div>
          <div style={{display: "flow", textAlign: "center"}}>
            <Button id={"reload-screen"} style={{margin: "0.625em"}} variant={"contained"} onClick={() => (window.location.href = "/adm/dashboard")}>
              <Typography>Go to Dashboard</Typography>
            </Button>
            <div />
            <Button id={"reload-screen"} style={{margin: "0.625em"}} variant={"contained"} onClick={this.handleReloadComponent}>
              <Typography>Reload the software component to retry</Typography>
            </Button>
            <div />
            {navigator.clipboard && (
              <Button id={"copy-to-clipboard"} style={{margin: "0.625em"}} variant={"contained"} onClick={this.handleCopyToClipboard}>
                <Typography>Copy error details to your clipboard</Typography>
              </Button>
            )}
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
