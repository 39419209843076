import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Container, IconButton, FormGroup } from '@mui/material';
import { Form, useNavigate } from 'react-router-dom';
// import { useSession } from '../auth/SessionContext';
import { useServerAPI } from '../Session';
import { useAuth } from '../Authentication';
import logo from '../assets/logo-nav-canada-blue.svg';
import ContrastIcon from '@mui/icons-material/Contrast';

interface LoginProps {
  // onAuth: () => void;
  nextPage: string;
  onToggleTheme: () => void;
}

const Login: React.FC<LoginProps> = ({nextPage ,onToggleTheme}) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [requestId, setRequestId] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isTotpStep, setIsTotpStep] = useState<boolean>(false);
  const [totpCode, setTotpCode] = useState<string>('');
  const [isTotpValid, setIsTotpValid] = useState<boolean>(true);
  const { setAuthTokens } = useAuth();
  const server = useServerAPI();

  const navigate = useNavigate();

  const handleLogin = async () => {
    // server-side username/password validation
    try {
      const response = await server.signIn({ username, password });
      if (response.data['token']) {
        setAuthTokens(response.data.token);
        // onAuth();

        console.log(`Will navigate to ${nextPage}`);
        navigate(nextPage);
      } else {
        setIsInvalid(true);
      }
    } catch (error: any) {
      if (typeof error === 'object' && error !== null && error.response.data.error.details) {
        setIsTotpStep(true);
        setIsTotpValid(true);
        setRequestId(error.response.data.error.details.requestId);
        setIsInvalid(false);
      } else {
        setIsInvalid(true);
      }
      console.log(error);
    }
  };

  const handleTotpValidation = async () => {
    // server-side TOTP validation
    try {

      const response = await server.validateTotp({ requestId, totpCode });
      if (response.data.token) {
        // setAuthTokens("Have a JWT");
        setAuthTokens(response.data.token);
        // onAuth();
        console.log(`Will navigate to ${nextPage}`);
        navigate(nextPage);
      } else {
        setIsTotpValid(false);
      }
    } catch (error: any) {
      setTimeout(() => setIsTotpStep(false), 2000);
      // setIsInvalid(false);
      setIsTotpValid(false);
      console.log(error);
    }
  };

  var promtpUser = <>
    <TextField
      margin="normal"
      fullWidth
      label="Username"
      value={username}
      onChange={(e) => setUsername(e.target.value)} />
    <TextField
      margin="normal"
      fullWidth
      label="Password"
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      error={isInvalid}
      helperText={isInvalid ? 'Invalid credentials' : ''} />
    <Button
      fullWidth
      variant="contained"
      // color="primary"
      onClick={handleLogin}
    >
      Login
    </Button>
  </>

  var promptTotp = <>
    <Typography component="h1" variant="h5">
      Enter TOTP Code
    </Typography>
    <TextField
      margin="normal"
      fullWidth
      autoComplete="one-time-code"
      label="TOTP Code"
      color='success'
      value={totpCode}
      onChange={(e) => setTotpCode(e.target.value)}
      error={!isTotpValid}
      helperText={!isTotpValid ? 'Invalid TOTP Code' : ''} />
    <Button
      fullWidth
      variant="contained"
      // color="primary"
      onClick={handleTotpValidation}
    >
      Verify
    </Button>
  </>;



  return (
    <Container component="main" maxWidth="xs" >
      {/* <IconButton onClick={()=>onToggleTheme()} sx={{position:'absolute',top:1,left:1}}><ContrastIcon/></IconButton> */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <img
          src={logo}
          alt="Icon"
          style={{
            width: '100px',
            height: '100px',
            margin: '1em'
          }}
        />

        <Typography component="h1" variant="h5" >
          IBS Login
        </Typography>
        <form>
        {isTotpStep ? promptTotp : promtpUser}
        </form>

      </Box>
      <Box position={'absolute'} bottom={-2} left={2}>
      <Typography variant='caption'>version {process.env.REACT_APP_VERSION}</Typography>
      </Box>
    </Container>
  );
};

export default Login;