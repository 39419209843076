import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';

import QuizIcon from '@mui/icons-material/Quiz';

export const UnknownCard = () => {
    const [count, setCount] = React.useState<number>();
    const navigate = useNavigate();
    

    React.useEffect(() => {
        setCount(1);
        console.log('.');
    }, [])


    return (
        <Card variant='elevation'  sx={{aspectRatio:1, minHeight:"170px", margin:1, border:'2px solid red',cursor:'pointer'}}>
            <div onClick={()=>{navigate('/adm/unknowns')}} >
            <CardHeader title="Unknowns" avatar={<QuizIcon/>}/>
            <CardContent >
                Unassigned
                <Typography variant='h2'>{count}</Typography>
            </CardContent>
            </div>
        </Card>
    )
}
