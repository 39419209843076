import { Box, CircularProgress, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useServerAPI } from '../Session';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CustomerFleet } from './CustomerFleet';
import { CustomerInfo } from './CustomerInfo';
import { CustomerInvoice } from './CustomerInvoice';
import { CustomerFlight } from './CustomerFlights';
import { useAuth } from '../Authentication';

export const CustomerDetailPage = () => {

  const [customer, setCustomer] = React.useState<any>();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const params = useParams();
  const server = useServerAPI();
  const navigate = useNavigate();
  const { userData } = useAuth();

  React.useEffect(() => {

    if (!loading && (!customer || params.entityId !== customer.entityId)) {
      setLoading(true);
      server.readCustomer(params.entityId as string).then(result => {
        console.log(`Retrieved customer `, result);
        setCustomer(result);
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.entityId])


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleSave = (updatedCustomer:any) => {
    setLoading(true);
    server.saveCustomer(updatedCustomer).then( ()=>setLoading(false));
  }

  return (
    // <Paper elevation={1} sx={{ margin: '20px', height:'1000px' }} >
    <Box margin={1}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {loading ? <CircularProgress /> : null}
        {userData?.authPayload?.Customer_Number ? null : <IconButton onClick={() => navigate('/adm/customers')}><ArrowBackIosIcon /></IconButton>}
        <Typography variant='subtitle1' paddingRight={'1em'}>Customer</Typography>
        <Typography variant='h6' >{customer?.data.Customer}</Typography>
      </Box>

      <Tabs value={currentTab} onChange={handleChangeTab} aria-label="basic tabs example">
        <Tab label="Details" value={0} />
        <Tab label="Fleet" value={1} />
        <Tab label="Flights" value={2} />
        <Tab label="Invoices" value={3} />
      </Tabs>
      <div hidden={currentTab !== 0}>
        <Box display={'flex'} justifyContent={'center'} width={'100%'} visibility={currentTab === 0 ? 'visible' : 'hidden'}>
          <CustomerInfo customer={customer} onSave={handleSave}/>
        </Box>
      </div>
      <div hidden={currentTab !== 1}>
        <Box display={'flex'} justifyContent={'center'} width={'100%'} visibility={currentTab === 1 ? 'visible' : 'hidden'}>
          <CustomerFleet customer={customer} />
        </Box>
      </div>
      <div hidden={currentTab !== 2}>
        <Box display={'flex'} justifyContent={'center'} width={'100%'} visibility={currentTab === 2 ? 'visible' : 'hidden'}>
          <CustomerFlight customer={customer} />
        </Box>
      </div>
      <div hidden={currentTab !== 3}>
        <Box display={'flex'} justifyContent={'center'} width={'100%'} visibility={currentTab === 3 ? 'visible' : 'hidden'}>
          <CustomerInvoice customer={customer} />
        </Box>
      </div>
    </Box>
  )
}
