import React from 'react';
import { Box, Container, Grid2 as Grid, Typography } from '@mui/material';
import { CustomerCard } from './CustomerCard';
import { InvoiceCard } from './InvoiceCard';
import { useAuth } from '../Authentication';
import { useServerAPI } from '../Session';
import { UnknownCard } from './UnknownCard';
import { DisputedCard } from './DisputedCard';
import { AircraftCard } from './AircraftCard';


interface DashboardProps {

}

const Dashboard: React.FC<DashboardProps> = () => {
  const { cache, userData } = useAuth();
  const server = useServerAPI();

  React.useEffect(() => {
    if (typeof cache['Aircrafts'] === 'undefined') {
      console.log('Loading Aircrafts Cache');
      server.listAircraftType(1000, 1, {}, {}).then(r => {
        console.log('Retrieved Aircrafts', r);
        if (Array.isArray(r)) {
          cache['Aircrafts'] = r.reduce((a: any, e: any) => { a[e.data.ac_type] = e.data; return a }, {});
        }
      })
      // ensure we only do this once.
      cache['Aircrafts'] = [];
    } else {
      console.log(cache);
    }
  })

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="start"
      height="100vh"
      width={'100%'}
    >
      <Container fixed maxWidth='md'>
        <Typography variant="h3" margin={1}>IBS Dashboard</Typography>
        <Grid container spacing={2} alignSelf={'center'}>
          <Grid size={userData?.authPayload?.Customer_Number ? 0: 4} visibility={userData?.authPayload?.Customer_Number ? 'hidden' : 'inherit'} maxHeight={"20px"} maxWidth={"200px"}>
            <Container draggable>
            <CustomerCard />
            </Container>
          </Grid>
          <Grid size={userData?.authPayload?.Customer_Number ? 0: 4} visibility={userData?.authPayload?.Customer_Number ? 'hidden' : 'inherit'} maxWidth={"200px"}>
            <Container draggable>
            <UnknownCard />
            </Container>
          </Grid>
          <Grid size={userData?.authPayload?.Customer_Number ? 0: 4} visibility={userData?.authPayload?.Customer_Number ? 'hidden' : 'inherit'} maxWidth={"200px"}>
            <Container draggable>
            <DisputedCard />
            </Container>
          </Grid>
          <Grid size={4} maxWidth={"200px"}>
            <Container draggable>
            <InvoiceCard />
            </Container>
          </Grid>
          <Grid size={userData?.authPayload?.Customer_Number ? 4: 0} visibility={userData?.authPayload?.Customer_Number ?  'inherit':'hidden'} maxWidth={"200px"}>
            <Container draggable>
            <AircraftCard />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;