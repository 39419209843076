import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";


export const filterToSearchCriteria = (filters: GridFilterModel, forceCustomerNumber?:string|null) => {
    var searchCriteria: { [prop: string]: any } = {};
    for (const filter of filters.items) {
        switch (filter.operator) {
            case "contains":
                searchCriteria[filter.field] = { "$regex": `${filter.value}` };
                break;
            case "equals":
                searchCriteria[filter.field] = filter.value;
                break;
            case "startsWith":
                searchCriteria[filter.field] = { "$regex": `^${filter.value}` };
                break;
            case "endsWith":
                searchCriteria[filter.field] = { "$regex": `${filter.value}$` };
                break;
            case "isEmpty":
                searchCriteria[filter.field] = { "$exists": false };
                break;
            case "isNotEmpty":
                searchCriteria[filter.field] = { "$exists": true };
                break;
            case "isAnyOf":
                searchCriteria[filter.field] = { "$in": filter.value };
                break;

            default:
                break;
        }
    }    
    if (forceCustomerNumber) {
        // if the user has a set customer then force it.
        searchCriteria["data.Customer_Number"] = forceCustomerNumber;   
    }
    return searchCriteria;
}

export const sortToSortCriteria = (sortList?: GridSortModel) => {
    var sortCriteria: { [prop: string]: any } = {};
    if (sortList) {
        for (const sort of sortList) {
            sortCriteria[sort.field] = sort.sort === 'asc' ? 1 : -1;
        }
    }
    return sortCriteria;
}