import { Backdrop, Box, Button, CircularProgress, MenuItem, Paper, Select, SelectChangeEvent } from '@mui/material';
import React, { ReactNode, useState } from 'react'
import { BpmnJsReact, useBpmnJsReact,IBpmnElement } from "bpmn-js-react";

import { useServerAPI } from '../Session';

interface RuleDefinition {
  id: string,
  xml: string
}

export const RulesEdit = () => {

  const [diagramXML, setDiagramXML] = useState<string>();
  const [ruleDefinitions, setRuleDefinitions] = useState<Entity<RuleDefinition>[]>([]);
  const [selectedRule, setSelectedRule] = useState<Entity<RuleDefinition> | null>(null);
  // const [showScriptProp, setShowScriptProp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedWork, setSelectedWork] = useState<string>('_');
  const [selectedElement, setSelectedElement] = React.useState<IBpmnElement>();

  const bpmnReactJs = useBpmnJsReact();
  const server = useServerAPI();

  React.useEffect(() => {
    if (ruleDefinitions.length === 0) {
      setLoading(true);
      server.listRuleDefinitions(100, 1,{},{"data.id":1}).then((rules: any) => {
        if (Array.isArray(rules)) {
          console.log(`======== Got Rules `, rules);
          setRuleDefinitions(rules);
          setLoading(false);
        }
      })
    }

  }, [])

  React.useEffect(() => {
    console.log(`Selected Rule ${selectedRule?.data.id}`);
    if (selectedRule) setDiagramXML(selectedRule?.data.xml);
    setSelectedElement(undefined);
  }, [selectedRule]);


  const onError = (err: any) => {
    console.error(err);
  }

  const elementClickHandler = (value: any) => {
    console.log(`You clicked ${value}`, value);
    
    if (value.element && value.element.type === "bpmn:ScriptTask"){
      setSelectedElement(value.element);
      // debugger
      var prop = bpmnReactJs.getAttribute(value.element.id,"custom:action");
      console.log(`Script ${value.element.id} has custom:action ${prop}`);   
      if (typeof prop === 'string') {
        setSelectedWork(prop);
      } else {
        setSelectedWork('_');
      }
    } else {
      setSelectedElement(undefined);
    }

  }

  const handleWorkChange = (event: SelectChangeEvent<string | null>, child: ReactNode) => {
    if (selectedElement && typeof event.target.value === 'string') {
      bpmnReactJs.setAttribute(selectedElement.id,"custom:action",event.target.value);
      selectedElement.businessObject['custom:action'] = event.target.value;
      setSelectedWork(event.target.value);
    }
  }

  const handleRuleSelection = (event: SelectChangeEvent<string | null>, child: ReactNode) => {
    // debugger
    const selectedId = event.target.value;
    const rule = ruleDefinitions.find(r => r.data.id === selectedId);
    if (rule) { 
      setSelectedRule(rule);
    }
  }


  const handleSaveHandler = async () => {
    console.log('Did click save');
    var xml = await bpmnReactJs.saveXml({ format: true });
    if (typeof xml.xml === 'string') {
      if (selectedRule) {
        setLoading(true);
        var updatedRule: Entity<RuleDefinition> = { ...selectedRule } as Entity<RuleDefinition>;
        updatedRule.data = { id: selectedRule?.data.id ?? "NEW", xml: xml.xml };
        server.saveRuleDefinitions(updatedRule).then((res: Entity<RuleDefinition>) => {
          // updatedRule.version = res.version;
          setSelectedRule(res);
          setRuleDefinitions((values) => values.map(v => v.entityId === updatedRule.entityId ? res : v));
        }).catch(reason => {
          alert(`Error: Could not save rule ${updatedRule.data.id}}`);
          console.warn(reason);
        }).finally(() => setLoading(false));
      } else {
        var name = prompt("Rule Name:", "New Rule");
        if (typeof name === 'string' && name.length > 0) {
          setLoading(true);
          server.createRuleDefinitions({ id: name, xml: xml.xml }).then((res: Entity<RuleDefinition>) => {
            setRuleDefinitions((values) => [...values, res]);
            setSelectedRule({ ...res });
          }).catch(reason => {
            alert(`Error: Could not create new rule ${name}`);
            console.warn(reason);
          }).finally(() => setLoading(false));
        }
      }
    }

  }

  const handleCancel = () => {
    console.log('Did click cancel');
    if (selectedRule?.data.xml) bpmnReactJs.importXml(selectedRule?.data.xml);

  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        setDiagramXML(content);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Paper sx={{ marginTop: '5px', padding: 1 }}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Select
          size='small'
          sx={{ margin: '5px' }}
          displayEmpty
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          placeholder='Select Rule Definition'
          value={selectedRule?.data.id ?? null}
          renderValue={(selected: any) => {
            if (!selectedRule) {
              return <em>Select Rule Definition</em>;
            }
            return selected;
          }}
          // label="Rule"
          onChange={handleRuleSelection}>
          {ruleDefinitions.map(rd => (<MenuItem key={rd?.data?.id} value={rd?.data?.id} selected={selectedRule?.data?.id === rd?.data?.id}>{rd?.data?.id}</MenuItem>))}
        </Select>
        <input
          accept="text/*"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-upload"
        />
        <label htmlFor="file-upload"><Button variant="outlined" component="span" sx={{ margin: '5px' }}>Import File</Button></label>
        <Box flex={1} />
        {/* {loading && <CircularProgress />} */}
        <Button variant='contained' size='small' onClick={handleSaveHandler} sx={{ margin: '5px' }}>Save</Button>
        <Button variant='contained' size='small' onClick={handleCancel} sx={{ margin: '5px' }}>Cancel</Button>
      </Box>
      <Box display='flex' flexDirection={'row'}>
        <Box flex={1}>
          <BpmnJsReact useBpmnJsReact={bpmnReactJs} mode="edit" xml={diagramXML} height="900px" click={elementClickHandler} />
        </Box>
        {selectedElement && <Box width={"200px"} padding={1}>
          <Box display='flex' flexDirection={'column'}>
            Action
            <Select size='small' variant='standard' value={selectedWork} onChange={handleWorkChange} >
              <MenuItem selected={selectedWork==='_'} value={'_'} disabled><i>Select Action</i></MenuItem>
              <MenuItem selected={selectedWork==='fee'} value={'fee'}>Calculate Fee</MenuItem>
              <MenuItem selected={selectedWork==='chg'} value={'chg'}>Calculate Charge</MenuItem>
              <MenuItem selected={selectedWork==='adj'} value={'adj'}>Calculate Adj</MenuItem>
              <MenuItem selected={selectedWork==='notax'} value={'notax'}>No Tax</MenuItem>
              <MenuItem selected={selectedWork==='gstqst'} value={'gstqst'}>Calculate GST & QST</MenuItem>
              <MenuItem selected={selectedWork==='gst'} value={'gst'}>Calculate GST</MenuItem>
              <MenuItem selected={selectedWork==='nlhst'} value={'nlhst'}>Calculate NL HST</MenuItem>
              <MenuItem selected={selectedWork==='peihst'} value={'peihst'}>Calculate PEI HST</MenuItem>
              <MenuItem selected={selectedWork==='nshst'} value={'nshst'}>Calculate NS HST</MenuItem>
              <MenuItem selected={selectedWork==='nbhst'} value={'nbhst'}>Calculate NB HST</MenuItem>
              <MenuItem selected={selectedWork==='onhst'} value={'onhst'}>Calculate ON HST</MenuItem>
              <MenuItem selected={selectedWork==='qst'} value={'qst'}>Calculate QST</MenuItem>
              <MenuItem selected={selectedWork==='valid'} value={'valid'}>Validate</MenuItem>
            </Select>
          </Box>
        </Box>}
      </Box>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  )
}
