import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ProtectedRoutes from './components/ProtectedRoute';
import TopBar from './components/TopBar';
import CustomerListPage from './Customer/ListPage';
import Authentication from './Authentication/Authentication';
import { CustomerDetailPage } from './Customer/DetailPage';
import { RulesEdit } from './RuleEngine/RulesEdit';
import AircraftListPage from './Aircrafts/ListPage';
import AerodromesListPage from './Aerodromes/ListPage';
import RateListPage from './MetaData/RateListPage';
import TaxeListPage from './MetaData/TaxeListPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Processing } from './components/Processing';
import { Unknown } from './Unknown/Unknown';

const theme = createTheme({
  colorSchemes: {
    dark: true,
  },
});

const App: React.FC = () => {
  const [defaultTheme, setDefaultTheme] = useState(true);

  // console.log(`isAuthenticated:${isAuthenticated}`);
  return (

    <Authentication>
      {/* <AppTheme disableCustomTheme={defaultTheme}> */}
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={
                <Login onToggleTheme={() => setDefaultTheme((v)=>!v)} nextPage="/adm/dashboard" />
              }
            />
            <Route path='/adm/' element={<ProtectedRoutes defaultTheme={defaultTheme} header={<TopBar onToggleTheme={() => setDefaultTheme((v)=>!v)}/>} expireRedirect={'/logout'} />} >
              <Route path="/adm/dashboard" element={<Dashboard />} />
              <Route path="/adm/customers" element={<CustomerListPage />} />
              <Route path="/adm/aircrafts" element={<AircraftListPage />} />
              <Route path="/adm/aerodromes" element={<AerodromesListPage />} />
              <Route path="/adm/rules" element={<RulesEdit />} />
              <Route path="/adm/rates" element={<RateListPage />} />
              <Route path="/adm/taxes" element={<TaxeListPage />} />
              <Route path="/adm/unknowns" element={<Unknown />} />
              <Route path="/adm/processing" element={<Processing />} />
              <Route path="/adm/customer/:entityId" element={<CustomerDetailPage />} />
            </Route>
            <Route path='/logout' loader={async ({ params, request }) => {
              return null;
            }}
              element={<Navigate to="/login" />}
            />
            <Route path="*" element={<Navigate to="/login" />} />

          </Routes>
        </Router>
        </ThemeProvider>
      {/* </AppTheme> */}
    </Authentication>
  );
};

export default App;