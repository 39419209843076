import { Box } from '@mui/material'
import { DataGridPro as DataGrid, GridActionsCellItem, GridColDef, GridFilterModel, GridPaginationModel, GridSortModel, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-pro'
import React from 'react'
import { useServerAPI } from '../Session'
// import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import EditIcon from '@mui/icons-material/Edit';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { filterToSearchCriteria, sortToSortCriteria } from '../Session/Helpers';
import { CommonToolbar } from '../components/CommonToolbar';

interface CustomerFlightProps {
    customer: any
}

export const CustomerFlight = ({ customer }: CustomerFlightProps) => {

    const [loading, setLoading] = React.useState(false);
    const [flights, setFlights] = React.useState<any[]>();
    const [flightCount, setFlightCount] = React.useState(-1);
    // const [currentCustomerId, setCurrentCustomerId] = React.useState<string>();
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0,
    });
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: []
    })
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{sort:'desc',field:'data.date'}]);

    const server = useServerAPI();
    const dateFormatter = new Intl.DateTimeFormat('en-CA', { timeZone: 'UTC', dateStyle: 'long', timeStyle: 'long' })

    const flightColumns: GridColDef[] = [
        {
            field: 'data.ACID',
            valueGetter: (v, r) => {
                return r?.data.ACID
            },
            headerName: 'ACID',
            width: 90
        },
        {
            field: 'data.date',
            valueGetter: (v, r: any) => r?.data.date,
            valueFormatter: (v => dateFormatter.format(new Date(v))),
            headerName: 'Date',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.ac_type',
            valueGetter: (v, r: any) => r?.data.ac_type,
            headerName: 'Aircraft',
            flex: 1
            //   editable: true,
        },
        {
            field: 'data.depart',
            valueGetter: (v, r: any) => r?.data.depart,
            headerName: 'Depart',
            flex: 1,
        },
        {
            field: 'data.dest',
            valueGetter: (v, r: any) => r?.data.dest,
            headerName: 'Destination',
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,

            getActions: (params) => [
                // <Tooltip title={"Pay in external system"}>
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Update Flight"
                    // onClick={handlePaid(params)}
                    showInMenu
                />
                // </Tooltip>              
                ,
            ],
        },
    ];

    const handleFilterModelChange = (filterModel: GridFilterModel) => {
        console.debug(`filterModel`, filterModel);
        setFlightCount(-1);
        setFilterModel(filterModel);
    }
    const handleSortModelChange = (newsortModel: GridSortModel) => {
        console.debug(`filterModel`, newsortModel);
        setSortModel(newsortModel);
    }

    React.useEffect(() => {
        if (flightCount === -1 && customer?.data?.Customer_Number) {
            server.countFlight(filterToSearchCriteria(filterModel, customer?.data?.Customer_Number)).then(r => {
                if (typeof r === 'number') {
                    setFlightCount(r);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterModel,customer]);

    React.useEffect(() => {
        if (!loading && customer?.data?.Customer_Number) {
            setLoading(true);
            server.listFlight(paginationModel.pageSize, paginationModel.page + 1, filterToSearchCriteria(filterModel, customer?.data?.Customer_Number), sortToSortCriteria(sortModel)).then(r => {
                if (r) {
                    if (Array.isArray(r)) {
                        setFlights(r);
                        console.log(`Got flight list ${r.length}`);
                    }
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel, filterModel, sortModel,customer])


    // React.useEffect(() => {

    //     if (customer && !loading && (!flights || currentCustomerId !== customer?.data.Customer)) {
    //         setLoading(true);
    //         server.listFlight(100, 1, { "data.Customer_Number": customer?.data.Customer_Number },{"data.date":-1}).then(result => {
    //             console.log(`Retrieved flights `, result);
    //             if (result) {
    //                 if (Array.isArray(result)) {
    //                     setFlights(result);
    //                     setCurrentCustomerId(customer?.data.Customer);
    //                     setFlightCount(result.length);
    //                 }
    //             }
    //             setLoading(false);
    //         });
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [customer]);


    const saveFleetAircraft = async (updatedRow: any) => {

        if (updatedRow["data.MTOW"]) {
            updatedRow.data.MTOW = updatedRow["data.MTOW"];
            delete updatedRow["data.MTOW"];
        }
        console.debug(`Saved Fleet updatedRow is `, updatedRow);
        var result = await server.saveFleet(updatedRow);
        console.debug(`Saved Fleet return row is `, result);
        return result;
    }

    const handleSaveFleetAircraftError = (error: any) => {
        console.warn(`Coult not update Aircraft Fleet data.`);
    }
      
    return (
        <Box maxHeight={"800px"} width={"800px"} padding={"20px"}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box>
                    Flights
                </Box>
            </Box>
            <DataGrid
                slots={{ toolbar: CommonToolbar }}
                autoHeight
                pagination 
                density='compact'
                loading={loading}
                rows={flights ?? []}
                getRowId={(r) => r.entityId}
                columns={flightColumns}
                rowCount={flightCount}
                paginationMode='server'
                onFilterModelChange={handleFilterModelChange}
                filterModel={filterModel}
                filterMode='server'
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    );
}
