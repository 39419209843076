import { Box } from '@mui/material'
import { DataGridPro as DataGrid, GridActionsCellItem, GridColDef, GridFilterModel, GridPaginationModel, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro'
import React from 'react'
import { useServerAPI } from '../Session'
import { filterToSearchCriteria, sortToSortCriteria } from '../Session/Helpers';

import PaymentIcon from '@mui/icons-material/Payment';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { CommonToolbar } from '../components/CommonToolbar';

interface CustomerFleetProps {
    customer: any
}

export const CustomerInvoice = ({ customer }: CustomerFleetProps) => {

    const [loading, setLoading] = React.useState(false);
    const [invoices, setInvoices] = React.useState<any[]>();
    const [invoiceCount, setInvoiceCount] = React.useState(-1);
    // const [currentCustomerId, setCurrentCustomerId] = React.useState<string>();
    const server = useServerAPI();
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
        pageSize: 25,
        page: 0,
    });
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: []
    })
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ sort: 'desc', field: 'data.date' }]);

    const currencyFormmater = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' })

    const invoiceColumns: GridColDef[] = [
        {
            field: 'data.datePaid',
            valueGetter: (v, r) => {
                return r?.data.datePaid
            },
            headerName: 'Date',
            width: 120
        },
        {
            field: 'data.companyName',
            valueGetter: (v, r: any) => r?.data.companyName,
            headerName: 'Desc',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.status',
            valueGetter: (v: string, r: any) => r.data.status ?? "",
            // type:'boolean',
            headerName: 'Status',
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'data.amount',
            valueGetter: (v, r: any) => r?.data.amount,
            align: 'right',
            valueFormatter: (v) => currencyFormmater.format(v),
            flex: 1.5,
            //   editable: true,
            headerName: 'Amount',
            headerAlign: 'right',
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,

            getActions: (params) => [
                // <Tooltip title={"Pay in external system"}>
                <GridActionsCellItem
                    icon={<PaymentIcon />}
                    label="Pay (external)"
                    onClick={handlePaid(params)}
                    showInMenu
                />
                // </Tooltip>              
                ,
                <GridActionsCellItem
                    icon={<ThumbDownIcon />}
                    label="Dispute"
                    onClick={handleDispute(params)}
                    showInMenu
                />,
            ],
        },
    ];

    const handleFilterModelChange = (filterModel: GridFilterModel) => {
        console.debug(`filterModel`, filterModel);
        setInvoiceCount(-1);
        setFilterModel(filterModel);
    }
    const handleSortModelChange = (newsortModel: GridSortModel) => {
        console.debug(`filterModel`, newsortModel);
        setSortModel(newsortModel);
    }

    React.useEffect(() => {
        if (invoiceCount === -1 && customer?.data?.Customer_Number) {
            server.countInvoice(filterToSearchCriteria(filterModel, customer?.data?.Customer_Number)).then(r => {
                if (typeof r === 'number') {
                    setInvoiceCount(r);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterModel, customer]);

    React.useEffect(() => {
        if (!loading && customer?.data?.Customer_Number) {
            setLoading(true);
            server.listInvoice(paginationModel.pageSize, paginationModel.page + 1, filterToSearchCriteria(filterModel, customer?.data?.Customer_Number), sortToSortCriteria(sortModel)).then(r => {
                if (r) {
                    if (Array.isArray(r)) {
                        setInvoices(r);
                        console.log(`Got Invoices list ${r.length}`);
                    }
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel, filterModel, sortModel, customer])



    // React.useEffect(() => {
    //     if (customer && !loading && (!invoices || currentCustomerId !== customer?.data.Customer)) {
    //         setLoading(true);
    //         server.listInvoice(100, 1, { "data.Customer_Number": customer?.data.Customer_Number }).then(result => {
    //             console.log(`Retrieved fleet `, result);
    //             if (result) {
    //                 if (Array.isArray(result)) {
    //                     setInvoices(result);
    //                     setCurrentCustomerId(customer?.data.Customer);
    //                     setInvoiceCount(result.length);
    //                 }
    //             }
    //             setLoading(false);
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [customer]);

    const handleDispute = (r: GridRowParams<any>) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        console.log(`dispute clicked `, r);
        setInvoices((cur) => cur?.map(i => (i.entityId === r.id ? { ...i, data: { ...i.data, status: 'Disputed' } } : i)));
    }
    const handlePaid = (r: GridRowParams<any>) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        console.log(`paid clicked `, r);
        setInvoices((cur) => cur?.map(i => (i.entityId === r.id ? { ...i, data: { ...i.data, status: 'Paid' } } : i)));
    }

    return (
        <Box maxHeight={"800px"} width={"800px"} padding={"20px"}>
            Invoices
            <DataGrid
                slots={{ toolbar: CommonToolbar }}
                autoHeight
                pagination
                loading={loading}
                rows={invoices ?? []}
                getRowId={(r) => r.entityId}
                columns={invoiceColumns}
                rowCount={invoiceCount}
                // pageSizeOptions={[10,20,50]}
                autoPageSize
                checkboxSelection
                paginationMode='server'
                onFilterModelChange={handleFilterModelChange}
                filterModel={filterModel}
                filterMode='server'
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                sortingMode='server'
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
            />
        </Box>
    );
}


