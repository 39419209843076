import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'
import { useServerAPI } from '../Session';
import FlightIcon from '@mui/icons-material/Flight';
import { useAuth } from '../Authentication';

export const AircraftCard = () => {
    const [count, setCount] = React.useState<number>();

    const server = useServerAPI();
    const {userData} = useAuth();

    React.useEffect(() => {
        if (count === undefined) {
            var criteria = userData?.authPayload?.Customer_Number ? {"data.Customer_Number":userData?.authPayload?.Customer_Number} : {}
            server.countFleet(criteria).then(value => {
                if (typeof value === 'number') {
                    setCount(value);
                }
            });
        }
    });
    return (

        <Card variant='elevation'  sx={{aspectRatio:1, minHeight:"170px", margin:1}}>
            <CardHeader title="Aircrafts" avatar={<FlightIcon/>}/>
            <CardContent>
                <Typography variant='h2'>{count}</Typography>
            </CardContent>

        </Card>
    )
}
