import { Box, darken, IconButton, lighten, styled, Theme } from '@mui/material'
import { DataGridPro as DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro'
import React from 'react'
import { useServerAPI } from '../Session'
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAuth } from '../Authentication';
import { CommonToolbar } from '../components/CommonToolbar';

interface CustomerFleetProps {
    customer: any
}

export const CustomerFleet = ({ customer }: CustomerFleetProps) => {

    const [fleet, setFleet] = React.useState<any[]>();
    const [fleetCount, setFleetCount] = React.useState(0);
    const [currentCustomerId, setCurrentCustomerId] = React.useState<string>();
    const [loading, setLoading] = React.useState(false);
    const [aircraftTypes, setAircraftTypes] = React.useState<any[]>();

    const { cache } = useAuth();

    const server = useServerAPI();


    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (typeof cache['Aircrafts'] === 'undefined') {
            console.log('Loading Aircrafts Cache');
            server.listAircraftType(1000, 1, {}, {}).then(r => {
                console.log('Retrieved Aircrafts', r);
                if (Array.isArray(r)) {
                    cache['Aircrafts'] = r.reduce((a: any, e: any) => { a[e.data.ac_type] = e.data; return a }, {});
                    setAircraftTypes(cache['Aircrafts']);
                }
            })
            // ensure we only do this once.
            cache['Aircrafts'] = [];
        } else {
            console.log('Aircrafts', cache);
            setAircraftTypes(cache['Aircrafts']);
        }
    });

    const fleetColumns: GridColDef[] = [
        {
            field: 'data.Registration',
            valueGetter: (v, r) => {
                return r?.data.Registration
            },
            headerName: 'ID',
            width: 90
        },
        {
            field: 'data.cexpcode',
            valueGetter: (v, r: any) => r?.data.cexpcode,
            headerName: 'cexpcode',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.ac_type',
            valueGetter: (v, r: any) => r?.data.ac_type,
            headerName: 'Type',
            width: 100
            //   editable: true,
        },
        {
            field: 'data.desc',
            valueGetter: (v, r: any) => aircraftTypes?.[r?.data.ac_type]?.["Description "],
            headerName: 'Desc',
            flex: 2
            //   editable: true,
        },
        {
            field: 'data.MTOW',
            valueGetter: (v, r: any) => r?.data.MTOW,
            headerName: 'MTOW',
            flex: 2,
            editable: true,
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,

            getActions: (params) => [
                // <Tooltip title={"Pay in external system"}>
                <GridActionsCellItem
                    icon={<AirplanemodeInactiveIcon />}
                    label="Expire Aircraft"
                    // onClick={handlePaid(params)}
                    showInMenu
                />
                // </Tooltip>              
                ,
            ],
        },
    ];

    React.useEffect(() => {

        if (customer && !loading && (!fleet || currentCustomerId !== customer?.data.Customer)) {
            setLoading(true);
            server.listFleet(100, 1, { "data.Customer": customer?.data.Customer }).then(result => {
                console.log(`Retrieved fleet `, result);
                if (result) {
                    if (Array.isArray(result)) {
                        setFleet(result);
                        setCurrentCustomerId(customer?.data.Customer);
                        setFleetCount(result.length);
                    }
                }
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer]);


    const saveFleetAircraft = async (updatedRow: any) => {

        if (updatedRow["data.MTOW"]) {
            updatedRow.data.MTOW = updatedRow["data.MTOW"];
            delete updatedRow["data.MTOW"];
        }
        console.debug(`Saved Fleet updatedRow is `, updatedRow);
        var result = await server.saveFleet(updatedRow);
        console.debug(`Saved Fleet return row is `, result);
        return result;
    }

    const isValid = (row:any) => {
        var valid = (parseFloat(aircraftTypes?.[row.data.ac_type].Min_MTOW) < parseFloat(row.data.MTOW) );
        console.log(` Is Valid ${valid} for ${row.data.ac_type} with min ${aircraftTypes?.[row.data.ac_type].Min_MTOW} vs ${row.data.MTOW}`);
        return valid;
    }
    const handleSaveFleetAircraftError = (error: any) => {
        console.warn(`Coult not update Aircraft Fleet data.`);
    }
    const generateRandomBytes = function generateRandomBytes(length: number) {
        const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var randomBytes = [];
        for (var i = 0; i < length; i++) {
            // Generate a random byte (0-255)
            var v = Math.floor(Math.random() * alpha.length)
            randomBytes.push(alpha[v]);
        }
        return randomBytes.join("");
    }

    const buildAircraft = () => {
        return {
            "entityId": generateRandomBytes(32),
            "version": 8,
            "acl": {
                "other": 2
            },
            "ownerId": null,
            "expiresAt": null,
            "timeToLive": null,
            "createdAt": 1726681776398,
            "updatedAt": 1726682971453,
            "entityType": "CustomerFleet",
            data: {
                "Customer": customer.data.Customer,
                "cexpcode": "A",
                "Registration": "CB" + generateRandomBytes(3),
                "ac_type": "B738",
                "MTOW": "70.8",
                "BC_REC_STATUS": "NEW",
                "bachIndex": 0
            }

        };
    }
    const handleAddFleetAircraft = () => {
        setFleet((cur) => [...cur ?? [], buildAircraft()]);
        setFleetCount((cur) => cur + 1);
    }
    const getBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
        backgroundColor: darken(color, coefficient),
        ...theme.applyStyles('light', {
          backgroundColor: lighten(color, coefficient),
        }),
      });
      
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .fleet-row-invalid': {
            ...getBackgroundColor(theme.palette.error.main, theme, 0.7),
            '&:hover': {
              ...getBackgroundColor(theme.palette.error.main, theme, 0.6),
            },
            '&.Mui-selected': {
              ...getBackgroundColor(theme.palette.error.main, theme, 0.5),
              '&:hover': {
                ...getBackgroundColor(theme.palette.error.main, theme, 0.4),
              },
            },
          }
    }));

    return (
        <Box maxHeight={"800px"} width={"1000px"} padding={"20px"}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Box>
                    Fleet
                </Box>
                <IconButton onClick={handleAddFleetAircraft}>
                    <AddCircleIcon />
                </IconButton>
            </Box>
            <StyledDataGrid
                slots={{ toolbar: CommonToolbar }}
                density='compact'
                autoHeight
                pagination
                loading={loading}
                rows={fleet ?? []}
                getRowId={(r) => r.entityId}
                columns={fleetColumns}
                rowCount={fleetCount}
                // pageSizeOptions={[10,20,50]}
                autoPageSize
                // editMode="row"
                processRowUpdate={(updatedRow, originalRow) =>
                    saveFleetAircraft(updatedRow)
                }
                onProcessRowUpdateError={handleSaveFleetAircraftError}
                getRowClassName={(params) => isValid(params.row) ? '' : `fleet-row-invalid`}
            // paginationMode='server'
            // onFilterModelChange={handleFilterModelChange}
            // filterModel={filterModel}
            // filterMode='server'
            // onPaginationModelChange={setPaginationModel}
            // paginationModel={paginationModel}
            // sortingMode='server'
            // sortModel={sortModel}
            // onSortModelChange={handleSortModelChange}
            />
        </Box>
    );
}
