import { Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid2, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React from 'react'
import { useServerAPI } from '../Session';
import RefreshIcon from '@mui/icons-material/Refresh';

export const Processing = () => {

    const server = useServerAPI();

    const [newBatches, setNewBatches] = React.useState<any[]>();
    const [selectedBatch, setSelectedBatch] = React.useState<string | null>(null);
    const [invoiceableCusomer, setInvoiceableCusomer] = React.useState<any[]>();
    const [selectedInvoiceable, setSelectedInvoiceable] = React.useState<string | null>(null);
    const [scheduledBatches, setSceduledBatches] = React.useState<any[]>();
    const [loadedBatch, setLoadedBatch] = React.useState(false);
    const [loadedInvoices, setLoadedInvoices] = React.useState(false);
    
    React.useEffect(() => {
        if (!loadedBatch) {
            setLoadedBatch(true);
            server.listBatchSummaries(20, { "data.status": "NEW" }).then(r => {
                if (Array.isArray(r)) {
                    setNewBatches(r.map(e => e.data));
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadedBatch]);

    React.useEffect(() => {
        //Get list of Customers
        if (!loadedInvoices) {
            setLoadedInvoices(true);
            server.listInvoicableCusomer({}).then((c: any) => {
                console.log(`List of Invoiceable`, c);
                if (Array.isArray(c.data?.data?.response?.items)) {
                    setInvoiceableCusomer(c.data?.data?.response?.items);
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadedInvoices])

    
    React.useEffect(() => {
        //Get list of Customers
        if (!loadedBatch) {
            server.listScheduledBatches({}).then((c: any) => {
                console.log(`List of Schedule Batches`, c);
                if (c.data?.data?.response) {
                    setSceduledBatches(Object.keys(c.data?.data?.response).map(k=>({batchName:k,scheduleStartTime:c.data?.data?.response[k]})));
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadedBatch])


    function handleChangeBatch(event: SelectChangeEvent<unknown>): void {
        setSelectedBatch(event.target.value as string);
    }
    const resetBatchSelection = () => {
        setLoadedBatch(false);
        setSelectedBatch(null);
    }
    const handleProcessBatch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        server.processBatch({ batchName: selectedBatch }).then( ()=>{
            setLoadedBatch(false);//reload the batches
        });
    }

    function handleChangeInvoiceable(event: SelectChangeEvent<unknown>): void {
        setSelectedInvoiceable(event.target.value as string);
    }
    const resetInvoiceableSelection = () => {
        setSelectedInvoiceable(null);
        setLoadedInvoices(false);
    }
    const handleSendInvoice = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // server.processBatch({ batchName: selectedInvoiceable });
        console.log(selectedInvoiceable);
        if (selectedInvoiceable) server.sendFlightEmail({Customer_Number:selectedInvoiceable});

    }

    function handleProcessRefresh(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        setLoadedBatch(false);
    }

    return (
        <Container maxWidth="md" >
            <div>Processing</div>
            <Grid2 container margin={1} spacing={2}>
                <Grid2 size={6}>
                    <Card variant="outlined" contextMenu='test'>
                        <CardHeader title="Billing Batches" />
                        <CardContent>
                            Generate Billing Data for batch:
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // label="Batches"
                                value={selectedBatch}
                                onChange={handleChangeBatch}
                            >
                                {newBatches?.map(b => (<MenuItem key={b.batchName} value={b.batchName}>{b.batchName}</MenuItem>))}
                            </Select>
                        </CardContent>
                        <CardActions>
                            <Button variant='contained' onClick={resetBatchSelection}>Reset</Button>
                            <Button variant='contained' disabled={!selectedBatch} onClick={handleProcessBatch} >Process</Button>
                            <Box sx={{flex:1}}/>
                            <IconButton onClick={handleProcessRefresh} ><RefreshIcon/></IconButton>
                        </CardActions>
                    </Card>
                    {(scheduledBatches && scheduledBatches.length > 0) && <Box>
                        <Typography variant='h6' marginTop={2}>Scheduled</Typography>
                        {scheduledBatches.map(s=>(<li>Batch: <strong>{s.batchName}</strong> at {new Date(s.scheduleStartTime).toLocaleTimeString()}</li>))}
                        </Box>}
                </Grid2>
                <Grid2 size={6}>
                    <Card variant="outlined" contextMenu='test'>
                        <CardHeader title="Email Flight Summary" />
                        <CardContent>
                            Send Last 10 flights export to:
                            <Select
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                // label="Batches"
                                value={selectedInvoiceable}
                                onChange={handleChangeInvoiceable}
                            >
                                {invoiceableCusomer?.map(c => (<MenuItem key={c.Customer_Number} value={c.Customer_Number}>[{c.Customer_Number}] {c.Customer}</MenuItem>))}
                            </Select>
                        </CardContent>
                        <CardActions>
                            <Button variant='contained' onClick={resetInvoiceableSelection}>Reset</Button>
                            <Button variant='contained' disabled={!selectedInvoiceable} onClick={handleSendInvoice} >Send Invoice</Button>
                        </CardActions>
                    </Card>
                </Grid2>
            </Grid2>
        </Container>

    )
}
